.middle {
  background-color: rgba(243, 236, 110, 0.581);
}

.vitality {
  display: flex;
}
.avatar{
  flex-grow:7;
  min-height: 150px;
}

.reset-button {
  height: 50px;
}

.vitality-stats {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.vitality-stats>.shields, .vitality-stats>.health{
  font-size: 1em;
}

.row,.middle-row {
  display: flex;
  flex-direction: row;
}
.middle-row{
  justify-content: space-evenly;
}

.attacker-damage{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shields {
  color:royalblue
}

.pulse{
 transform: scale(1);
	animation: pulse 2s infinite;
}
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  .eliminator{
      border: none;
      cursor: default;
      border-radius: 40px;
      margin: 0 100px 0 100px;
      padding: 10px;
      background-color: rgb(198, 166, 255);
      border:seagreen 2px solid;
      text-align: center;
  }

  .research-img>.can-change:hover{
    color: green;
    cursor: pointer;
  }

  .research-img>.locked {
    color: red
  }

  .amiddle{
    position: relative;
}

  .astyle {
    content: "";
    position: absolute;
    width: 100%;
    background-color: #FFF;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  .amiddle:hover:after {
    visibility: visible;
    transform: scaleX(1);
  }

  .loadingicon{
    max-width: 15em;
    /* animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s; */
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }