.breathe {
  animation-name: size;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction:alternate-reverse;
}
@keyframes size {
  0% {
  }
  60% {

    background-color: rgba(255, 0, 0, 0.471);
  }
  100% {
  }
}
.selector, .faction, .production {
  cursor: pointer;
}
.attribute, .selector{
  display: flex;
  border-radius: 40px;
  padding: 5px;
  margin: 10px;
  justify-content: center;
  background-color: rgba(167, 175, 60, 0.444);
  border:seagreen 2px solid;

}
.input-area{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.unit-search{
  flex-grow: 5
}
.button-group{
  flex-grow: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 2em;
}

.random-button, .reset-button{
  cursor: pointer;
}
.random-button:hover{
  transform: scale(1.2);
  transition: all ease 500ms;
}

.reset-button:hover{ 
  animation: pulsate .75s infinite;
  animation-timing-function: linear;   
}

@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.clear-tags{
  cursor: pointer;
  font-size: 1.5em;
}

.clear-tags:hover{ 
  animation: pulsate .75s infinite;
  animation-timing-function: linear;   
}

.tagged-attribute{
  background-color: seagreen;
  border-radius: 10px ;
  padding: 10px;
  margin: 4px;
  text-align: center;
  border: 2px greenyellow solid;
  color: white;
  text-shadow: 2px 2px black;
}

.tagged-attribute:hover{
  cursor: pointer;
  opacity: .7;
}

.used-tag{
  opacity: .4;
}
.used-tag:hover{
  background-color: red;
}
.search-by-tag>.tagged-attribute:hover{
  background-color: red;
}